<template>
  <section class="container">
    <HSAlert :dismissible="true" :active="alertActive" :message="resultMessage" :type="result" />
    <h1 data-test-id="title" class="text-center">Learner Self Registration</h1>
    <h2 class="text-center">
      You are registering for
      <span data-test-id="account_name">{{ accountName }}</span>
    </h2>
    <div class="align-self-center">
      <HSForm display="block">
        <HSField
          appendIcon="email"
          dataTest="email"
          inputType="email"
          label="email"
          name="email"
          :required="true"
          @input="handleEmailInput"
          :value="email"
        />
        <HSField
          dataTest="firstname"
          label="First Name"
          name="firstname"
          :required="true"
          @input="handleFirstnameInput"
          :value="firstname"
        />
        <HSField
          dataTest="lastname"
          label="Last Name"
          name="lastname"
          :required="true"
          @input="handleLastnameInput"
          :value="lastname"
        />
        <HSSelectInput
          :data="selectDataForAccount"
          model="name"
          :canType="true"
          dataTest="facility"
          label="Facility"
          :isRequired="true"
          @onSelect="handleFacilityInput"
        />
        <HSSelectInput
          :data="selectDataForDepartment"
          model="name"
          :canType="true"
          dataTest="department"
          label="Department"
          :isRequired="true"
          @onSelect="handleDepartmentInput"
          :isDisabled="noDepartments"
        />
        <HSButton dataTest="create" type="is-success" @onClick="handleSubmit"
          >Register Account
        </HSButton>
      </HSForm>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Api from '../api'
import { SelfRegistrationComponentInterface, facility, ResponseCreateUser } from '../types'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {
  HSForm,
  HSField,
  HSSelectInput,
  HSButton,
  HSAlert,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@healthscholars/healthscholars-vue'

export default defineComponent({
  name: 'SelfRegistration',
  computed: {
    carCode(): string {
      if (
        this.$route &&
        this.$route.query &&
        this.$route.query.car_code &&
        typeof this.$route.query.car_code == 'string'
      ) {
        return this.$route.query.car_code
      } else {
        return ''
      }
    },
    accountName(): string | undefined {
      return this.account?.name
    },
    nickname(): string | null {
      return this.email
    },
    selectDataForAccount(): { id: string; name: string }[] {
      if (!this.account || !this.account.account_facilities) return []

      return this.account.account_facilities
        .map(({ facility }) => {
          return { id: facility.institution_id, name: facility.name }
        })
        .sort((a, b) => (a.name < b.name ? -1 : 1))
    },
    selectedFacility(): facility | null | undefined {
      return this.account?.account_facilities?.find(
        (account_facility) => account_facility.facility.institution_id == this.facilityId
      )?.facility
    },
    selectDataForDepartment(): { id: string; name: string }[] {
      if (!this.selectedFacility || !this.selectedFacility.facilities_departments) return []

      return this.selectedFacility.facilities_departments
        .map(({ department }) => ({
          id: department.id,
          name: department.name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1))
    },
    noDepartments(): boolean {
      return this.selectDataForDepartment.length == 0
    },
  },
  mounted() {
    Api.getAccountByCarCode(this.carCode).then((account) => {
      if (account) this.account = account
    })
  },
  data(): SelfRegistrationComponentInterface {
    return {
      account: null,
      email: null,
      firstname: null,
      lastname: null,
      facilityId: null,
      departmentId: null,
      emailValue: '',
      result: 'is-success',
      resultMessage: '',
      alertActive: false,
    }
  },
  components: {
    HSForm,
    HSField,
    HSSelectInput,
    HSButton,
    HSAlert,
  },
  methods: {
    handleEmailInput(email: string): void {
      this.email = email
    },
    handleFirstnameInput(firstname: string): void {
      this.firstname = firstname
    },
    handleLastnameInput(lastname: string): void {
      this.lastname = lastname
    },
    handleFacilityInput({ id }: { id: string; name: string }): void {
      if (id) this.facilityId = id
    },
    handleDepartmentInput({ id }: { id: string; name: string }): void {
      if (id) this.departmentId = id
    },
    handleSubmit(): void {
      const newLearner = {
        nickname: this.email,
        email: this.email,
        password: 'qw98JYUL&0897jwfpglwAWFP!@#$', // this password is never used for learners so it's okay to be hard coded
        given_name: this.firstname,
        family_name: this.lastname,
        institution_id: this.facilityId?.toString(),
        department_id: this.departmentId?.toString(),
        account_id: this.account?.account_id.toString(),
        role: 'Learner',
      }

      Api.selfRegisterLearner(newLearner)
        .then((response: ResponseCreateUser) => {
          if (response.errors.length > 0) {
            return console.error(response.errors)
          }

          this.result = 'is-success'
          this.resultMessage = `${this.firstname} has been registered as a Learner`
          this.email = null
          this.firstname = null
          this.lastname = null
          this.facilityId = null
          this.departmentId = null
          this.alertActive = true
        })
        .catch((error) => {
          console.error('error creating learner:', error)
          this.result = 'is-danger'
          this.resultMessage = `There was an error registering`
          this.alertActive = true
        })
    },
  },
})
</script>
