export interface AuthUser {
  sub: string
  picture: string
}
export interface actorUser {
  userId: string
  accountId: string
  role: Role
  institutionId: string
  email: string
  picture?: string
  nickname: string
  accountCode?: string
}
export interface User {
  blocked: boolean
  nickname: string
  role: Role
  email: string
  sf_account: {
    account_id: string
    name: string
  }
  user_id: string
  facility: {
    institution_id: number
    name: string
  }
  firstname: string
  lastname: string
  access: string[]
}

export interface getUsersRequest {
  pages: number
  users: User[]
}

export enum Role {
  SuperAdmin = 'Super Admin',
  Admin = 'Admin',
  Learner = 'Learner',
  undefined = '',
}

export interface Notification {
  message: string
  type: 'is-success' | 'is-danger' | 'is-info' | 'is-warning'
  duration: number
  dissmissible: boolean
}

export interface account {
  id: string
  account_id: string
  name: string
  account_facilities?: { facility: facility }[]
}

export interface facility {
  institution_id: string
  name: string
  facilities_departments?: { department: department }[]
}

export interface department {
  id: string
  name: string
}

export interface UserForm {
  email: string
  password?: string
  given_name: string
  family_name: string
  nickname: string
  institution_id: string
  department_id: string
  account_id: string
  role: string
  access: string[]
  user_id?: string
}

export interface RequestForm {
  actor: {
    id: string
    accountId: string
    role: Role
  }
  action: {
    user: UserForm
  }
}

export interface ResponseCreateUser {
  errors: string[]
  user?: User
  warnings: string[]
}

export interface Department {
  [key: string]: { id: string; name: string }
}

export interface SelfRegistrationComponentInterface {
  account: account | null
  email: string | null
  firstname: string | null
  lastname: string | null
  facilityId: string | null
  departmentId: string | null
  emailValue: string
  result: 'is-success' | 'is-danger'
  resultMessage: string
  alertActive: boolean
}

// ! Panel interfaces
export interface UserInEditPanel {
  access: string[]
  blocked: boolean
  email: string
  facility: { institution_id: string; name: string }
  firstname: string
  lastname: string
  nickname: string
  role: string
  sf_account: { account_id: string; name: string }
  user_id: string
  department: { id: string; name: string }
}
export interface UserCreatePanel {
  access: string[]
  sf_account: { account_id: string; name: string }
  department: { id: string; name: string }
  facility: { institution_id: string; name: string }
  email: string
  family_name: string
  given_name: string
  nickname: string
  password: string
  role: string
}
export interface identifiersPanels {
  account_id: string
  department_id: string
  institution_id: string
  role: string
}
export interface selectFormat {
  id: string
  value: string
}
export interface selectFormatCreate {
  id: string | Role
  value: string
}

export interface readinessReport {
  name: string
  created_at: string
  pdf_report: string
}

export interface ceCert {
  name: string
  created_at: string
  pdf_certificate: string
}

export interface Learner {
  nickname: string | null
  email: string | null
  password: string | null
  given_name: string | null
  family_name: string | null
  institution_id: string | null | undefined
  department_id: string | null | undefined
  account_id: string | null | undefined
  role: string | null
}
