import { defineComponent, ref, reactive, watch, computed } from 'vue'
import { useAuthStore, usePanelStore, useUsersStore } from '../../store'
import useForms from '../../composables/useForms'
import SelectComponent from '../SelectComponent.vue'
import {
  HSSidebar,
  HSButton,
  HSField,
  HSForm,
  HSSelectInput,
  HSConfirmButton,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@healthscholars/healthscholars-vue'

import { identifiersPanels, UserInEditPanel, readinessReport } from '../../types'
import useNotificationStore from '../../store/NotificationStore'

export default defineComponent({
  components: {
    HSSidebar,
    HSButton,
    HSField,
    HSForm,
    HSSelectInput,
    SelectComponent,
    HSConfirmButton,
  },
  setup() {
    const {
      currentUserRole,
      formatStoreFacilities,
      roles,
      formatStoreDepartments,
      accounts,
      loadings,
      deleteUser,
      getFacilities,
      getDepartments,
      accountsAvailableToAssign,
      assignedAccounts,
      prepareAccess,
    } = useForms()
    const panelStore = usePanelStore()
    const usersStore = useUsersStore()
    const authStore = useAuthStore()
    const notificationStore = useNotificationStore()

    const form = ref<HTMLFormElement>()
    const selectAccountKey = ref('k-account')
    const selectFacilityKey = ref('k-facility')
    const selectDepartmentKey = ref('k-department')
    const rolesKey = ref('k-roles')
    const reportFilterKey = ref('k-report-filter')
    const ceCertFilterKey = ref('k-ceCert-filter')

    const facilities = ref()
    const departments = ref()
    const oUser = ref<UserInEditPanel>({
      access: [],
      blocked: false,
      department: { id: '', name: '' },
      email: '',
      facility: { institution_id: '', name: '' },
      firstname: '',
      lastname: '',
      nickname: '',
      role: '',
      sf_account: { account_id: '', name: '' },
      user_id: '',
    })
    const local = reactive<identifiersPanels>({
      account_id: '',
      institution_id: 'Loading...',
      department_id: '',
      role: '',
    })
    const appFilter = ref('All applications')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const reports = computed(() => panelStore.getUserReports(oUser.value.mysql_user_id))
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ceCerts = computed(() => panelStore.getUserReports(oUser.value.mysql_user_id))
    const reportsFiltered = computed(() => {
      if (appFilter.value === '' || appFilter.value === 'All applications') return reports.value
      const filtered = reports.value.filter((report) => report.name === appFilter.value)
      return filtered
    })
    const ceCertsFiltered = computed(() => {
      if (appFilter.value === '' || appFilter.value === 'All applications') return ceCerts.value
      const filtered = ceCerts.value.filter((cert) => cert.name === appFilter.value)
      return filtered
    })
    const applicationList = computed(() => {
      const list = ['All applications', ...reports.value.map((report) => report.name)]
      //remove duplicates from array
      const uniqueList = list.filter((item, index) => list.indexOf(item) === index)
      return uniqueList.map((item) => ({ id: item, value: item }))
    })
    const ceCertList = computed(() => {
      const list = ['All applications', ...ceCerts.value.map((cert) => cert.name)]
      //remove duplicates from array
      const uniqueList = list.filter((item, index) => list.indexOf(item) === index)
      return uniqueList.map((item) => ({ id: item, value: item }))
    })
    const openReport = (report: readinessReport) => {
      window.open(report.pdf_report, '_blank')
    }

    const isAssignAccounts = computed(() => local.role === 'Admin' && local.account_id !== '')

    const showDeleteButton = (item: UserInEditPanel) => {
      return (
        (authStore.isAdmin || authStore.isSuperAdmin) &&
        authStore.getId !== item?.user_id &&
        item.blocked === false
      )
    }

    const refreshFields = () => {
      selectAccountKey.value += 'a'
      selectFacilityKey.value += 'f'
      selectDepartmentKey.value += 'd'
      rolesKey.value += 'r'
    }

    const resetForm = () => {
      oUser.value.access = []
      oUser.value.blocked = false
      oUser.value.department = { id: '', name: '' }
      oUser.value.email = ''
      oUser.value.facility = { institution_id: '', name: '' }
      oUser.value.firstname = ''
      oUser.value.lastname = ''
      oUser.value.nickname = ''
      oUser.value.role = ''
      oUser.value.sf_account = { account_id: '', name: '' }
      oUser.value.user_id = ''
      local.account_id = ''
      local.institution_id = ''
      local.department_id = ''
      local.role = ''
      accountsAvailableToAssign.value = []
      assignedAccounts.value = []
      refreshFields()
    }
    const handleOnchange = (field: string, value: string) => {
      local[field as keyof identifiersPanels] = value
    }
    const handleFilterApp = (value: string) => {
      appFilter.value = value
    }

    const isDisabled = (value: string) => oUser.value[value as keyof UserInEditPanel] === ''

    const handleAccess = (value: string) => {
      if (!value) return
      oUser.value.access.push(value)
      prepareAccess(oUser.value.access, isAssignAccounts.value)
    }

    const deleteAccess = (index: number, accountId: string): void => {
      if (accountId === local.account_id) {
        notificationStore.addNotification(
          `You cannot delete the default account of the user`,
          'is-danger'
        )
        return
      }
      oUser.value.access = oUser.value.access.filter((id) => id !== accountId)
      assignedAccounts.value.splice(index, 1)
      prepareAccess(oUser.value.access, isAssignAccounts.value)
    }

    const formatDate = (date: string) => {
      const dateObj = new Date(date)
      return dateObj.toLocaleDateString()
    }

    const validateForm = () => {
      if (form.value) {
        let htmlValidator = true
        htmlValidator = form.value.validate()
        return htmlValidator
      }
      return false
    }

    const saveUser = async () => {
      if (validateForm()) {
        loadings.save = true
        const payload = {
          actor: {
            id: authStore.user.userId,
            role: authStore.user.role,
            accountId: authStore.user.accountId,
          },
          action: {
            user: {
              access: oUser.value.access,
              account_id: local.account_id,
              department_id: local.department_id,
              institution_id: local.institution_id,
              role: local.role,
              email: oUser.value.email,
              family_name: oUser.value.lastname,
              given_name: oUser.value.firstname,
              nickname: oUser.value.nickname,
              user_id: panelStore.userId,
            },
          },
        }
        await usersStore.updateUser(payload)
        loadings.save = false
      } else {
        form.value!.validate()
      }
    }

    watch(panelStore, async () => {
      if (!panelStore.isEditPanelOpen) {
        panelStore.unSelectRow()
      }
      if (panelStore.userId && panelStore.isEditPanelOpen) {
        const user: unknown = usersStore.getUserById(panelStore.userId)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const oMysqlId = user?.mysql_user_id
        const access: string[] = await usersStore.requestAccessByUserId(panelStore.userId)
        await panelStore.requestUserReports(oMysqlId)
        await panelStore.requestUserCeCerts(oMysqlId)
        oUser.value = {
          ...(user as UserInEditPanel),
          access,
          department: { id: '', name: 'Loading...' },
        }
        oUser.value.department = await usersStore.requestDepartmentByUserId(oUser.value.user_id)
        prepareAccess(oUser.value.access, isAssignAccounts.value)
      }
    })
    watch(
      () => local.account_id,
      async (account_id) => {
        if (account_id !== '') {
          oUser.value.access.push(account_id)
          prepareAccess(oUser.value.access, isAssignAccounts.value)
          oUser.value.department = { id: '', name: '' }
          await getFacilities(account_id)
          facilities.value = formatStoreFacilities(account_id)
        }
      }
    )
    watch(
      () => local.institution_id,
      async (institution_id) => {
        if (institution_id !== '') {
          await getDepartments(institution_id)
          departments.value = formatStoreDepartments(institution_id)
          selectDepartmentKey.value += 'd'
        }
      }
    )
    watch(isAssignAccounts, () => {
      if (isAssignAccounts.value) prepareAccess(oUser.value.access, isAssignAccounts.value)
    })

    return {
      // Propperties
      panelStore,
      currentUserRole,
      oUser,
      selectAccountKey,
      selectFacilityKey,
      selectDepartmentKey,
      rolesKey,
      facilities,
      departments,
      loadings,
      local,
      accountsAvailableToAssign,
      assignedAccounts,
      reportFilterKey,
      ceCertFilterKey,
      // Computed
      accounts,
      roles,
      isAssignAccounts,
      showDeleteButton,
      formatDate,
      reportsFiltered,
      reports,
      applicationList,
      appFilter,
      ceCertList,
      ceCertsFiltered,
      // Methods
      handleOnchange,
      isDisabled,
      saveUser,
      resetForm,
      deleteUser,
      handleAccess,
      deleteAccess,
      openReport,
      handleFilterApp,
      // Refs
      form,
    }
  },
})
