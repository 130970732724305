import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import AppView from '../views/AppView.vue'
import UsersTableView from '../views/UsersTableView.vue'
import BannerComponent from '../components/BannerComponent.vue'
import { authGuard, homeGuard } from '../auth/authGuard'
import SelfRegistration from '../components/SelfRegistrationComponent.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: AppView,
    children: [
      {
        path: '/',
        name: 'landing',
        component: BannerComponent,
        beforeEnter: homeGuard,
      },
      {
        path: 'users',
        name: 'users',
        component: UsersTableView,
        beforeEnter: authGuard,
      },
      {
        path: 'self-registration',
        name: 'self-registration',
        component: SelfRegistration,
        beforeEnter: homeGuard,
      },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
